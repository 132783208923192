import React from 'react';
import intwineLogo from '../../images/intwineconnect_white.png';
import AddGatewayTable from '../../tables/AddGatewayTable';
import Grid from '@mui/material/Grid';

const addGateway: React.FC = () => {
    return (
        <div>
            <div className="navbar">
                <div className='searchLogo'>
                    <img src={intwineLogo} alt="Logo" width={"180em"}></img>
                </div>
            </div>

            <h2 style={{ textAlign: 'left', paddingLeft: "50px", paddingBottom: '10px', borderStyle: 'solid', borderColor: 'grey', borderTop: 1, borderRight: 0, borderLeft: 0 }}>Add a Gateway</h2>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <AddGatewayTable />
                </Grid>
            </Grid>
        </div>
    );
};
export default addGateway;