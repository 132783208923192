import React from 'react';
import { Props } from '../areas/gateways/IcgData';
import Button from '@mui/material/Button';
import { Grid } from '@mui/material';


const CommandButtons: React.FC<Props> = ({ icgData }) => {
    if (!icgData) {
        return <div>Loading...</div>
    }


    return (
        <div>
            <h1 style={{ fontSize: '100%', paddingRight: '40%', color: 'black' }}>Commands</h1>
            <Grid>
                <Button variant='outlined' style={{ backgroundColor: '#129a77', color: 'white' }}>
                    testLED
                </Button>
                <Button variant='outlined' style={{ backgroundColor: '#129a77', color: 'white' }}>
                    LEDwan
                </Button>
                <Button variant='outlined' style={{ backgroundColor: '#129a77', color: 'white' }}>
                    FWupdate
                </Button>
                <Button variant='outlined' style={{ backgroundColor: '#129a77', color: 'white' }}>
                    Reboot
                </Button>
            </Grid>
        </div>
    );
};

export default CommandButtons;