import React, { useEffect } from 'react';

interface PutICGProps {
    // changedValues: { id: any, field: any, value: any; }[];
    updatedIcgData: any;
    serialID: any;
    sendRequestCompleteRef: React.MutableRefObject<boolean>;
}

const PutICG: React.FC<PutICGProps> = ({ updatedIcgData, serialID, sendRequestCompleteRef }) => {
    useEffect(() => {
        const fetchICGData = async () => {
            try {
                console.log("PUT ICG Object: ", updatedIcgData.name);
                var abc = updatedIcgData;
                console.log("TYPE: ", typeof (abc));

                // const formattedData = updatedIcgData.map((item: { name: string; input: string; }) => ({
                //     name: item.name,
                //     input: item.input,
                // }));

                // console.log("Formatted Data: ", formattedData);
                // console.log("Request Body: ", JSON.stringify(formattedData));


                // let accessToken = getToken('access');
                const accessToken=""; //TODO

                const icgdata = await fetch(`${process.env.REACT_APP_API_URL}/gateways/${serialID}`, {
                    method: 'PUT',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        name: updatedIcgData.name
                        // updatedIcgData
                        // name: "LORAIN_COUNTY_METRO_PARKS_25",
                        // "billingPlanId": "00002",
                    }),
                    // body: JSON.stringify({
                    //     'name ': 'LORAIN_COUNTY_METRO_PARKS_44'
                    // }),
                });
                sendRequestCompleteRef.current = true;
                if (icgdata.ok) {
                    console.log("Request Accepted and Working");
                } else {
                    console.error('Request failed: ', icgdata.status);
                }
            } catch (error) {
                console.error("Error: ", error);
            }
        };
        // window.location.reload();
        if (sendRequestCompleteRef) {
            fetchICGData();
            // sendRequestCompleteRef.current = false;
            // Reload the page to have GET request working
        }
    }, [serialID, sendRequestCompleteRef, updatedIcgData]);


    return (
        <div>

        </div>
    );

}; export default PutICG;