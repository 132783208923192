import {UserDataStorage} from "../../UserDataStorage";
import {Navigate, Outlet} from "react-router-dom";

export const ProtectedLayout=()=>{
    const userData = UserDataStorage.getUserData()
    console.log("in protected layout")

    if(!userData) {
        console.log("navigating to login")
        return <Navigate replace to ={"/login"} />
    }

    return (
        <>
            <Outlet />
        </>
    );
}