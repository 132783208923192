import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import {createRoot} from 'react-dom/client';
import './styling/index.css';
import App from './App';
import {Provider} from "react-redux";
import store from "./store";

const domNode = document.getElementById('root');
const root = createRoot(domNode!);

root.render(
    <Provider store={store}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </Provider>
);
