import React from 'react';
import { Props } from './IcgData';
import GatewayNetConfig from '../../tables/GatewayNetConfig';
import CommandButtons from '../../tables/CommandButtons';
import GatewayInfo from '../../tables/GatewayInfo';
import GatewayWanStatus from '../../tables/GatewayWanStatus';
import DirectMessage from '../../tables/DirectMessage';
import ProvisionAndDevices from '../../tables/ProvisionAndDevices';
import DeviceDataUsage from '../../tables/DeviceDataUsage';
// import RSRP from '../tables/RSRP';
import Devices from '../../tables/Devices';
import Button from '@mui/material/Button';
// import addGateway from '../AdminPages/addGateway';
// import uploadGateway from '../AdminPages/uploadGateway';
import { Link } from "react-router-dom";
import {useLoginData} from "../../slices/authSlice";


const ICG100AND200: React.FC<Props> = ({ icgData }) => {
    const token = useLoginData()

    if (!icgData) {
        return <div>Loading...</div>
    }

    const { sku } = icgData;

    console.log(`Token is ${token}`)

    return (
        <div>
            <div style={{ position: 'relative' }}>
                <h1 style={{ width: '100%', color: 'white', backgroundColor: '#129a77' }}>100/200 Message</h1>
                <span style={{ color: '#129a77' }}> IGUID: {sku?.substring(0, 7)}</span>
                <div style={{ paddingBottom: '5%' }}>
                    <Link to="../"><Button variant='outlined' style={{ backgroundColor: '#129a77', color: 'white' }} >
                        Logout
                    </Button></Link>
                </div>

                {(
                    <div>
                        {/* <Button variant='outlined' style={{ backgroundColor: '#129a77', color: 'white' }} >
                            <Link to="/AdminPages/addGateway">Add Gateway</Link>
                        </Button>
                        <Button variant='outlined' style={{ backgroundColor: '#129a77', color: 'white' }} >
                            <Link to="/AdminPages/uploadGateway">Upload Gateway</Link>
                        </Button> */}
                        <Link to="/AdminPages/addGateway">
                            <Button variant='outlined' style={{ backgroundColor: '#129a77', color: 'white' }}>
                                Add Gateway
                            </Button>
                        </Link>
                        <Link to="/AdminPages/uploadGateway">
                            <Button variant='outlined' style={{ backgroundColor: '#129a77', color: 'white' }}>
                                Upload Gateway
                            </Button>
                        </Link>
                    </div>
                )}
            </div>

            <span><CommandButtons icgData={icgData} /></span>

            <span><GatewayInfo icgData={icgData} /></span>

            <span><GatewayNetConfig icgData={icgData} /></span>
            <div>
                <h1 style={{ color: 'black', paddingRight: '37%', fontSize: 14 }} >Gateway Wan Status: </h1>
                <GatewayWanStatus icgData={icgData} />
            </div>
            {/* <RSRP icgData={icgData} /> */}
            <div>
                <h1 style={{ color: 'black', paddingRight: '37%', fontSize: 14 }} >Gateway Data Usage: </h1>
                <DeviceDataUsage icgData={icgData} />
            </div>
            <div>
                <h1 style={{ color: 'black', paddingRight: '37%', fontSize: 14 }} >Discovered Devices: </h1>
                <ProvisionAndDevices icgData={icgData} />
            </div>
            <div>
                <h1 style={{ color: 'black', paddingRight: '37%', fontSize: 14 }} >Devices: </h1>
                <Devices icgData={icgData} />
            </div>
            <div>
                <h1 style={{ color: 'black', paddingRight: '37%', fontSize: 14 }} >Send direct message to gateway: </h1>
                <DirectMessage icgData={icgData} />
            </div>

            <div style={{ paddingBottom: '5%' }}>
                <Link to="../SearchICG"><Button variant='outlined' style={{ backgroundColor: '#129a77', color: 'white' }}>
                    Search Another Device
                </Button></Link>
            </div>
        </div>
    );
};

export default ICG100AND200;