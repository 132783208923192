import React from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useDispatch} from "react-redux";
import {storeUserLoginData} from "../../slices/authSlice";
import {UserDataStorage} from "../../UserDataStorage";

// Adding in a load-state to prevent calls before they are ready
function Callback() {
    let [searchParams] = useSearchParams(); // eslint-disable-line no-use-before-define
    let codeString = searchParams.get('code');
    const code = codeString != null ? codeString : '';
    const navigate = useNavigate();
    const dispatch = useDispatch();


    const fetchAccessToken = async () => {

        const callback = `${window.location.origin}/callback`
        const response = await fetch(`${process.env.REACT_APP_OAUTH_ENDPOINT}/oauth2/token`, {
            method: 'POST',
            body: new URLSearchParams({
                'grant_type': 'authorization_code',
                'code': code,
                'client_id': process.env.REACT_APP_OAUTH_CLIENT_ID!,
                'redirect_uri': callback,
                'scope': 'openid profile email'
            }),
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        });

        if (response.ok) {
            const data = await response.json();

            const idTokenString = atob(JSON.stringify(data.id_token).split('.')[1]);
            const idToken = JSON.parse(idTokenString);
            console.log("TOKEN: ", idToken);

            dispatch(storeUserLoginData({
                token: data.access_token,
                groups: idToken["cognito:groups"],
                email: idToken.email
            }));
            UserDataStorage.setUserData({AccessToken: data.access_token, Email:idToken.email, Groups:idToken["cognito:groups"]})


        }

    };

    if (code) {
        fetchAccessToken().then(() => navigate('/'));
    }


    return (<div>Loading...</div>);

}

export default Callback;