import React, { useEffect, useRef, useState } from 'react';
import { Props } from '../areas/gateways/IcgData';
import PutICG from '../areas/gateways/PutICG';
import Button from '@mui/material/Button';
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import RadioButtonsCell from './RadioButtonsCell';
import AccessControlCell from './AccessControlCell';

const GatewayInfo: React.FC<Props> = ({ icgData }) => {
    const [cellActivationState, setSelectedCellularActivationState] = useState('');
    const [acFlagValue, setacFlagValue] = useState('');
    // const [changedValues, setChangedValues] = useState<{ id: any; field: any; value: any; }[]>([]);
    const [gridRows, setRows] = useState<GridRowsProp>([]);
    const [showPutICG, setShowPutICG] = useState(false);
    const [updatedIcgData, setupdatedIcgData] = useState({});
    const [deviceId, setDeviceId] = useState<string>('');
    const sendRequestCompleteRef = useRef(false);


    useEffect(() => {
        if (icgData) {
            const {
                accessControlFlagValue,
                billingPlanId,
                cellularActivationState,
                subscriptionId,
                mac,
                imei,
                iccid,
                sku,
                name,
                deviceData: { deviceId, ip, phoneNumber, wan, lan },
                // meta: { mfgDate },
            } = icgData;

            setDeviceId(deviceId || '');
            setSelectedCellularActivationState(cellularActivationState || '');
            setacFlagValue(accessControlFlagValue || '');
            setRows([
                { id: 0, name: 'serialId', input: deviceId, variableName: 'deviceId' },
                { id: 1, name: 'serial number', input: null, variableName: '' },
                { id: 2, name: 'mac', input: mac || null, variableName: 'mac' },
                { id: 3, name: 'imei', input: imei || null, variableName: 'imei' },
                { id: 4, name: 'iccid', input: iccid || null, variableName: 'iccid' },
                // { id: 5, name: 'mfgDate', input: mfgDate, variableName: 'mfgDate' },
                { id: 6, name: 'name', input: name || null, variableName: 'name' },
                { id: 7, name: 'SKU', input: sku || null, variableName: 'sku' },
                { id: 8, name: 'subs_id', input: subscriptionId || null, variableName: 'subscriptionId' },
                { id: 9, name: 'bp_id', input: billingPlanId || null, variableName: 'billingPlanId' },
                { id: 10, name: 'reset_date', input: " " || null, variableName: '' },
                { id: 11, name: 'IP', input: ip || null, variableName: 'ip' },
                { id: 12, name: 'Phone Number', input: phoneNumber || null, variableName: 'phoneNumber' },
                { id: 13, name: 'Firmware', input: null, variableName: '' },
                { id: 14, name: 'Wan', input: wan || null, variableName: 'wan' },
                { id: 15, name: 'Lan', input: lan || null, variableName: 'lan' },
                { id: 16, name: 'Uptime: Hours', input: null, variableName: '' },
                { id: 17, name: 'timestamp of uptine (Eastern Time)', input: null, variableName: '' },

                {
                    id: 18,
                    name: 'cellularActivationState',
                    input: cellularActivationState,
                    hasRadioButtons: true,
                    variableName: 'cellularActivationState'
                },
                {
                    id: 19,
                    name: 'Access Control',
                    input: accessControlFlagValue,
                    hasCheckboxes: true,
                    variableName: 'accessControlFlagValue'
                },
            ]);
        }
    }, [icgData]);


    // Returns the row id and changed value for when user make an edit on the page
    const handleInputChange = (id: any, value: any) => {
        const updatedRows = gridRows.map((row) => {
            if (row.id === id) {
                return { ...row, input: value };
            }
            return row;
        });

        // console.log("Updated rows", updatedRows);
        setRows(updatedRows);
        // console.log("Grid Rows: ", gridRows);
        console.log("ICG Data: ", icgData);
        // console.log("Updated Rows: ", updatedRows);
    };

    const handleModifyGateway = () => {
        const updatedRowsPayload = gridRows.map(row => ({ name: row.name, input: row.input, variableName: row.variableName }));
        var updatedIcgData = {
            ...icgData,
            ...updatedRowsPayload.reduce((acc: any, { name, input, variableName }) => {
                acc[name] = input;
                acc[variableName] = input;
                return acc;
            }, {}),
        };
        console.log("IcgData: ", icgData);
        setupdatedIcgData(updatedIcgData);
        setShowPutICG(true);
    }

    const handleAccessControlChange = (value: string) => {
        setacFlagValue(value);
    };
    const handleCellularActivationStateChange = (value: any) => {
        setSelectedCellularActivationState(value);
    };

    const columns: GridColDef[] = [
        { field: 'name', headerName: 'Name', editable: false, width: 200 },
        {
            field: 'input',
            headerName: 'Input',
            renderCell: (params) => {
                if (params.row.name === 'cellularActivationState' && params.row.hasRadioButtons) {
                    return (
                        <RadioButtonsCell
                            value={cellActivationState || ' '}
                            onChange={handleCellularActivationStateChange}
                        />
                    );

                } else if (params.row.name === 'Access Control' && params.row.hasCheckboxes) {
                    return (
                        <AccessControlCell
                            value={acFlagValue || ' '}
                            onChange={handleAccessControlChange}
                        />
                    );

                } else {
                    return (
                        <input
                            type="text"
                            value={params.row.input}
                            onChange={(event) => {
                                handleInputChange(params.id, event.target.value);
                            }}
                            style={{
                                width: '110%',
                                padding: '0',
                                margin: '0',
                                border: 'none',
                                outline: 'none',
                            }}
                        />
                    );
                }
            },
            flex: 1,
        }
    ];

    console.log("ICG DATA: ", icgData);

    return (
        <div style={{ paddingBottom: '2.5%', width: 500, height: 'flex', backgroundColor: 'white', color: 'white', paddingLeft: '25%' }}>
            <DataGrid
                slots={{
                    columnHeaders: () => null,
                }}
                sx={{
                    borderColor: 'black',
                    "& .MuiDataGrid-cell": {
                        border: 1,
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        border: 1,
                    },
                    "& .MuiDataGrid-columnHeader--withRightBorder": {
                        borderRight: 2,
                    },
                }}
                getRowHeight={(params) => {
                    return (params.id === 18 || params.id === 19) ? 100 : 25;
                }}
                rows={gridRows}
                columns={columns}
                hideFooterPagination
                hideFooterSelectedRowCount
                hideFooter
                showColumnVerticalBorder
                disableColumnMenu
                disableColumnFilter
                disableColumnSelector
                showCellVerticalBorder
            />
            {showPutICG && <PutICG updatedIcgData={updatedIcgData} serialID={deviceId} sendRequestCompleteRef={sendRequestCompleteRef} />}
            <Button variant='outlined' style={{ backgroundColor: '#129a77', color: 'white' }}
                onClick={handleModifyGateway}>
                Modify Gateway
            </Button>
        </div>
    );
};

export default GatewayInfo;