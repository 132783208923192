import React from 'react';
import { Props } from '../areas/gateways/IcgData';
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';

// In the future: will extract IGUID in first colum along with the second colum allowing user to edit network configuration
const rows: GridRowsProp = [
    { id: 1, custdataUse: 'custdata-use', FourG: ' ', eth: ' ', cb: ' ', OTA: ' ' },
];

// Defining columns for design purposes
const columns: GridColDef[] = [
    { field: 'custdata-use', headerName: 'custdata-use', flex: 1, sortable: false },
    { field: '4g', headerName: '4g', flex: 1, sortable: false },
    { field: 'eth', headerName: 'eth', flex: 1, sortable: false },
    { field: 'cb', headerName: 'cb', flex: 1, sortable: false },
    { field: 'OTA', headerName: 'OTA', flex: 1, sortable: false },
];

const deviceDataUsage: React.FC<Props> = ({ icgData }) => {
    if (!icgData) {
        return <div>Loading...</div>
    }

    return (
        <div style={{ paddingBottom: '2.5%', width: '55%', backgroundColor: 'white', color: 'white', paddingLeft: '25%' }}>
            <DataGrid
                sx={{
                    borderColor: 'black',
                    "& .MuiDataGrid-cell": {
                        border: 1,
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        border: 1,
                    },
                    "& .MuiDataGrid-columnHeader--withRightBorder": {
                        borderRight: 2,
                    },
                }}
                columnHeaderHeight={25}
                rowHeight={25}
                rows={rows}
                columns={columns}
                hideFooterPagination
                hideFooterSelectedRowCount
                hideFooter
                showColumnVerticalBorder
                disableColumnMenu
                disableColumnFilter
                disableColumnSelector
                showCellVerticalBorder
            />
        </div>
    );
};

export default deviceDataUsage;