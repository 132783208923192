import React from 'react';
import ICG100AND200 from './ICG100AND200';
import ICG150AND250 from './ICG150AND250';
import { IcgData } from './IcgData';

export const renderHome = (icgData: IcgData | null) => {
    if (!icgData) {
        return <p> Getting Data </p>;
    }

    if (icgData.sku) {
        let skuModel = parseInt(icgData.sku.substring(4, 7));
        if (skuModel === 100 || skuModel === 200) {
            return <ICG100AND200 icgData={icgData} />;

        } else if (skuModel === 150 || skuModel === 250) {
            return <ICG150AND250 icgData={icgData} />;
        } else {
            return <p>skuModel: {skuModel}</p>
        }
    }
}