import React from 'react';
import { Props } from '../areas/gateways/IcgData';
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';


const rows: GridRowsProp = [{
    id: 1, DeviceType: ' ', DeviceID: ' ', Manufacturer: ' ', Model: ' ',
    Firmware: ' ', Radio: ' ', Actions: ' '
}];
// Device Type	Device ID	Manufacturer	Model	Firmware	Radio	Actions



const columns: GridColDef[] = [
    { field: 'DeviceType', headerName: 'DeviceType', flex: 1, sortable: false },
    { field: 'DeviceID', headerName: 'DeviceID', flex: 1, sortable: false },
    { field: 'Manufacturer', headerName: 'Manufacturer', flex: 1, sortable: false },
    { field: 'Firmware', headerName: 'Firmware', flex: 1, sortable: false },
    { field: 'Radio', headerName: 'Radio', flex: 1, sortable: false },
    { field: 'Actions', headerName: 'Actions', flex: 1, sortable: false },
];

const ProvisionAndDevices: React.FC<Props> = ({ icgData }) => {
    if (!icgData) {
        return <div>Loading...</div>
    }

    return (
        <div style={{ paddingBottom: '2.5%', width: '70%', backgroundColor: 'white', color: 'white', paddingLeft: '25%' }}>
            <DataGrid
                sx={{
                    borderColor: 'black',
                    "& .MuiDataGrid-cell": {
                        border: 1,
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        border: 1,
                    },
                    "& .MuiDataGrid-columnHeader--withRightBorder": {
                        borderRight: 2,
                    },
                }}
                columnHeaderHeight={25}
                rowHeight={25}
                rows={rows}
                columns={columns}
                hideFooterPagination
                hideFooterSelectedRowCount
                hideFooter
                showColumnVerticalBorder
                disableColumnMenu
                disableColumnFilter
                disableColumnSelector
                showCellVerticalBorder
            />
        </div>
    );
};

export default ProvisionAndDevices;