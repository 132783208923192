import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from 'redux-saga';
import {authSlice} from "./slices/authSlice";
import userReducer from "./slices/userSlice";
import {rootSaga} from "./sagas/rootSaga";


const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
    reducer: {
        auth: authSlice.reducer,
    },
    middleware: (getDefaultMiddleware)=> getDefaultMiddleware().concat(sagaMiddleware),
    devTools: process.env.NODE_ENV !== 'production',
});

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;