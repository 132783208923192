import React from 'react';
import intwineLogo from '../../images/intwineconnect_white.png';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Props } from '../gateways/IcgData';

const uploadGateway: React.FC<Props> = ({ icgData }) => {

    return (
        <div>
            <div className="navbar">
                <div className='searchLogo'>
                    <img src={intwineLogo} alt="Logo" width={"180em"}></img>
                </div>
            </div>

            <h2 style={{ textAlign: 'left', paddingLeft: "50px", paddingBottom: '10px', borderStyle: 'solid', borderColor: 'grey', borderTop: 1, borderRight: 0, borderLeft: 0 }}>Upload a file</h2>
            <h3 style={{ textAlign: 'left', paddingLeft: "50px", fontSize: '15px', color: 'black' }}>Upload a file.</h3>
            <h3 style={{ textAlign: 'left', paddingLeft: "50px", fontSize: '10px', color: 'Grey' }}>File to upload</h3>

            <div>
                <Grid container spacing={2} alignItems="left" style={{ paddingLeft: '45px' }}>
                    <Grid item>
                        <Button variant="contained" style={{ background: 'lightgrey', color: 'black', height: '15px', width: '80px' }}>
                            Browse...
                        </Button>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1" color='black'>
                            No file selected
                        </Typography>
                    </Grid>
                </Grid>
            </div>

            <div style={{ paddingTop: '20px' }}>
                <Button variant='outlined' style={{ backgroundColor: '#129a77', color: 'white' }} >
                    Upload
                </Button>
            </div>

        </div>
    );

}; export default uploadGateway;