import React from 'react';
import { Props } from '../areas/gateways/IcgData';
// import Button from '@mui/material/Button';
import { Button, TextField, ThemeProvider, createTheme } from '@mui/material';
import { styled } from '@mui/system';


// In the future: will extract IGUID in first colum along with the second colum allowing user to edit network configuration


const GatewayNetConfig: React.FC<Props> = ({ icgData }) => {
    if (!icgData) {
        return <div>Loading...</div>
    }

    const theme = createTheme();

    const Body = styled('div')({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',

    });


    const ConfigData = styled('td')({
        border: '1px solid black',
        margin: 0,
        padding: 0,
    });

    const Table = styled('table')({
        borderCollapse: 'collapse',
        width: '75%',
        margin: 0,
        padding: 0,
        borderSpacing: 0,
        border: 0,
    });

    const TableData = styled('td')({
        border: '1px solid black',
        margin: 0,
        padding: 0,
        height: '40px',
    });

    return (
        <ThemeProvider theme={theme}>
            <Body style={{ alignItems: 'center' }}>
                <Table>
                    <colgroup>
                        <col span={1} style={{ width: '20px' }} />
                        <col span={1} />
                    </colgroup>
                    <tr>
                        <TableData>gateway id</TableData>
                        <TableData>{icgData.deviceData.deviceId}</TableData>
                    </tr>
                    <tr>
                        <ConfigData>network configuration</ConfigData>
                        <TextField
                            multiline
                            fullWidth
                            minRows={4}
                            variant="outlined"
                            placeholder="{ }"
                            sx={{
                                borderRadius: 0,
                                '&: hover': {
                                    border: '1px solid black',
                                }
                                // borderRight: '0.1px solid black',
                                // borderBottom: '0.1px solid black'
                            }}
                        />
                    </tr>
                </Table>
                <div>
                    <Button variant='outlined' style={{ backgroundColor: '#129a77', color: 'white' }}>
                        Send
                    </Button>
                    <Button variant='outlined' style={{ backgroundColor: '#129a77', color: 'white' }}>
                        Refresh From Gateway
                    </Button>
                </div>
            </Body>
        </ThemeProvider>
        // <div style={{ paddingBottom: '2.5%', width: "75%", height: 'flex', backgroundColor: 'white', color: 'white', paddingLeft: '25%' }}>
        //     <DataGrid
        //         slots={{
        //             columnHeaders: () => null,
        //         }}
        //         sx={{
        //             borderColor: 'black',
        //             "& .MuiDataGrid-cell": {
        //                 border: 1,
        //             },
        //             "& .MuiDataGrid-columnHeaders": {
        //                 border: 1,
        //             },
        //             "& .MuiDataGrid-columnHeader--withRightBorder": {
        //                 borderRight: 2,
        //             },
        //         }}
        //         getRowHeight={(params) => {
        //             return params.id === 1 ? 200 : 25;
        //         }}
        //         rows={rows}
        //         columns={columns}
        //         hideFooterPagination
        //         hideFooterSelectedRowCount
        //         hideFooter
        //         showColumnVerticalBorder
        //         disableColumnMenu
        //         disableColumnFilter
        //         disableColumnSelector
        //         showCellVerticalBorder
        //     />
        //     <Button variant='outlined' style={{ backgroundColor: '#129a77', color: 'white' }}>
        //         Send
        //     </Button>
        //     <Button variant='outlined' style={{ backgroundColor: '#129a77', color: 'white' }}>
        //         Refresh From Gateway
        //     </Button>

        // </div>
    );
};

export default GatewayNetConfig;