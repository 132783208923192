import React, {useEffect, useState} from 'react';
import {renderHome} from './RenderHome';
import {IcgData} from './IcgData';
import {useNavigate} from "react-router-dom";
import {UserDataStorage} from "../../UserDataStorage";


interface GetICGProp {
    accessToken: string | null;
    serialID: string;
}

const GetICG: React.FC<GetICGProp> = ({ accessToken, serialID }: GetICGProp) => {
    const [icgData, setIcgData] = useState<IcgData | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        console.log("Access Token", accessToken);
        const fetchICGData = async () => {
            console.log(`url is ${process.env.REACT_APP_API_URL}`)
            console.log(`clientId is ${process.env["REACT_APP_OAUTH_CLIENT_ID "]}`)
                // try {
                //     const data = await axiosInstance.get(
                //         `${process.env.REACT_APP_API_URL}/gateways/${serialID}`,
                //         {
                //             headers:{
                //                 'Authorization': `Bearer ${accessToken}`
                //             }
                //         }
                //     ).catch((error)=>{
                //         console.error(error)
                //     })
                // }
                // catch (error) {
                //     console.error(error);
                // }

                const response = await fetch(`${process.env.REACT_APP_API_URL}/gateways/${serialID}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    },
                })
                if (response.ok) {
                    const data = await response.json();
                    setIcgData(data);
                } else {
                    console.error('Request failed: ', response.status);
                    if(response.status==403) {
                        console.log("redirecting to login");
                        UserDataStorage.clearUserData();
                        navigate("/login")
                    }
                }

        };
        fetchICGData();


    }, [accessToken, serialID]);



    return (
        // Load a specific page based on SKU via grabbing SKU //
        <div>
            {renderHome(icgData)}
        </div>
    );
}; export default GetICG;