import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import RadioButtonsCell from './RadioButtonsCell';
import AccessControlCell from './AccessControlCell';

const AddGatewayTable: React.FC = () => {
    const [cellActivationState, setSelectedCellularActivationState] = useState('');
    const [acFlagValue, setacFlagValue] = useState('');
    // const [changedValues, setChangedValues] = useState<{ id: any; field: any; value: any; }[]>([]);
    const [gridRows, setRows] = useState<GridRowsProp>([]);
    const [showPutICG, setShowPutICG] = useState(false);
    // eslint-disable-next-line

    useEffect(() => {
        // icgData = getICGdata('account');
        // console.log(`TESTING`);
        // console.log(icgData);

        // setSelectedCellularActivationState(cellularActivationState || '');
        // setacFlagValue(accessControlFlagValue || '');
        setRows([
            { id: 0, name: 'IGUID', input: ' ' },
            { id: 1, name: 'iccid', input: ' ' },
            { id: 2, name: 'imei', input: ' ' },
            { id: 3, name: 'mac', input: ' ' },
            { id: 4, name: 'name', input: ' ' },
            { id: 5, name: 'SKU', input: ' ' },
            {
                id: 6,
                name: 'cellularActivationState',
                // input: cellularActivationState,
                hasRadioButtons: true
            },
            {
                id: 7,
                name: 'Access Control',
                // input: accessControlFlagValue,
                hasCheckboxes: true
            },
        ]);
    }, []);


    const handleGatewayInfoEdits = (params: any) => {
        // const { id, field, value } = params;
        // const updatedValues = [...changedValues];
        // const currentIndex = updatedValues.findIndex((item) => item.id === id && item.field === field);

        // if (currentIndex !== -1) {
        //     updatedValues[currentIndex].value = value;
        // } else {
        //     updatedValues.push({ id, field, value });
        // }
        // setChangedValues(updatedValues);
    }

    const handleInputChange = (id: any, value: any) => {
        const updatedRows = gridRows.map((row) => {
            if (row.id === id) {
                return { ...row, input: value };
            }
            return row;
        });

        setRows(updatedRows);
    };

    const handleModifyGateway = () => {
        setShowPutICG(true);

        return (
            <>
                {/* {showPutICG && <PutICG icgData={icgData} serialID={deviceId} />} */}
            </>
        );
        // return <PutICG changedValues={changedValues} />;
    }

    const handleAccessControlChange = (value: string) => {
        setacFlagValue(value);
    };
    const handleCellularActivationStateChange = (value: any) => {
        setSelectedCellularActivationState(value);
    };

    const columns: GridColDef[] = [
        { field: 'name', headerName: 'Name', editable: false, width: 200 },
        {
            field: 'input',
            headerName: 'Input',
            renderCell: (params) => {
                if (params.row.name === 'cellularActivationState' && params.row.hasRadioButtons) {
                    return (
                        <RadioButtonsCell
                            value={cellActivationState || ' '}
                            onChange={handleCellularActivationStateChange}
                        />
                    );

                } else if (params.row.name === 'Access Control' && params.row.hasCheckboxes) {
                    return (
                        <AccessControlCell
                            value={acFlagValue || ' '}
                            onChange={handleAccessControlChange}
                        />
                    );

                } else {
                    return (
                        <input
                            type="text"
                            value={params.row.input}
                            onChange={(event) => {
                                handleInputChange(params.id, event.target.value);
                                handleGatewayInfoEdits({
                                    id: params.id,
                                    field: 'input',
                                    value: event.target.value,
                                });
                            }}
                            style={{
                                width: '110%',
                                padding: '0',
                                margin: '0',
                                border: 'none',
                                outline: 'none',
                            }}
                        />
                    );
                }
            },
            flex: 1,
        }
    ];
    return (
        <div style={{ paddingBottom: '2.5%', width: '60%', backgroundColor: 'white', color: 'white', paddingLeft: '25%' }}>
            <DataGrid
                slots={{
                    columnHeaders: () => null,
                }}
                sx={{
                    borderColor: 'black',
                    "& .MuiDataGrid-cell": {
                        border: 1,
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        border: 1,
                    },
                    "& .MuiDataGrid-columnHeader--withRightBorder": {
                        borderRight: 2,
                    },
                }}
                getRowHeight={(params) => {
                    return (params.id === 6 || params.id === 7) ? 100 : 25;
                }}
                rows={gridRows}
                columns={columns}
                hideFooterPagination
                hideFooterSelectedRowCount
                hideFooter
                showColumnVerticalBorder
                disableColumnMenu
                disableColumnFilter
                disableColumnSelector
                showCellVerticalBorder
            />
            {/* {showPutICG && <PutICG changedValues={changedValues} serialID={deviceId} />} */}
            <Button variant='outlined' style={{ backgroundColor: '#129a77', color: 'white' }}
                onClick={handleModifyGateway}>
                Modify Gateway
            </Button>

            <div>
                <p>Add a device</p>
            </div>
        </div>
    );
};

export default AddGatewayTable;