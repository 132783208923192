import React from 'react';
import { Props } from '../areas/gateways/IcgData';
import Button from '@mui/material/Button';
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';


// In the future: will extract IGUID in first colum along with the second colum allowing user to edit network configuration

const columns: GridColDef[] = [
    { field: 'name', headerName: 'Name', editable: false, flex: 1, },
    {
        field: 'input',
        headerName: 'Input',
        type: 'String',
        editable: true,
        flex: 1,
    }
];

const rows: GridRowsProp = [
    { id: 0, name: 'File to upload' },
    { id: 1, name: 'config updated time:' }
];

const GatewayConfig: React.FC<Props> = ({ icgData }) => {
    if (!icgData) {
        return <div>Loading...</div>
    }

    return (
        <div style={{ paddingBottom: '2.5%', width: "75%", backgroundColor: 'white', color: 'white', paddingLeft: '25%' }}>
            <DataGrid
                slots={{
                    columnHeaders: () => null,
                }}
                sx={{
                    borderColor: 'black',
                    "& .MuiDataGrid-cell": {
                        border: 1,
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        border: 1,
                    },
                    "& .MuiDataGrid-columnHeader--withRightBorder": {
                        borderRight: 2,
                    },
                }}
                getRowHeight={(params) => {
                    return (params.id === 1) ? 200 : 25;
                }}
                rows={rows}
                columns={columns}
                hideFooterPagination
                hideFooterSelectedRowCount
                hideFooter
                showColumnVerticalBorder
                disableColumnMenu
                disableColumnFilter
                disableColumnSelector
                showCellVerticalBorder
            />
            <Button variant='outlined' style={{ backgroundColor: '#129a77', color: 'white' }}>
                Send
            </Button>
            <Button variant='outlined' style={{ backgroundColor: '#129a77', color: 'white' }}>
                Refresh From Gateway
            </Button>

        </div>
    );
};

export default GatewayConfig;