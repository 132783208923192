import {UserDataStorage} from "../../UserDataStorage";
import {Navigate, Outlet} from "react-router-dom";
import React from "react";


export const DefaultLayout=()=>{
    const userData = UserDataStorage.getUserData()
    console.log("in default layout")

    if(userData) {
        return <Navigate replace to={"/"}></Navigate>
    }
    return (
        <>
            <Outlet />
        </>
    );

}