import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {useSelector} from "react-redux";

export interface User {
    userEmail: string;
    token: string;
    groups: string[];
}

const initialState: User = {
    userEmail: "",
    token: "",
    groups: []

};

export const authSlice = createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
        storeUserLoginData: (user, action: PayloadAction<{ token: string, email: string, groups: string[] }>) => {
            user.token = action.payload.token;
            user.userEmail=action.payload.email;
            user.groups=action.payload.groups
        }
    },
});

export const {
    storeUserLoginData
} = authSlice.actions


export const useLoginData = () => useSelector((state: RootState) => state.auth);
