import React, {useEffect, useState} from 'react';
import { Button, ThemeProvider, createTheme } from '@mui/material';
import { styled } from '@mui/system';
import intwineLogo from '../../images/intwineconnect_white.png';
import { react } from '@babel/types';

const theme = createTheme();

const Body = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#129a77',
    color: '#129a77',
});

const Logo = styled('div')({
    marginBottom: '50px',
});

const StyledButton = styled(Button)({
    textDecoration: 'none',
    padding: '0.75rem 1rem',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    color: '#129a77',
    backgroundColor: 'white',
    boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
    '&:hover': {
        backgroundColor: 'white',
    },
    '&:active': {
        boxShadow: 'none',
        transform: 'scale(0.95)',
    },
});

const LoginPage: React.FC = () => {
    const callback = `${window.location.origin}/callback`

    console.log("in login page")


    return (
        <ThemeProvider theme={theme}>
            <Body>
                <Logo>
                    <img src={intwineLogo} alt="logo" />
                </Logo>
                <StyledButton
                    variant="contained"
                    color="primary"
                    as="a"
                    href={`${process.env.REACT_APP_OAUTH_ENDPOINT}/login?response_type=code&state=a889bed0-de1d-4076-8f8f-7008e92f4427&nonce=a11409f4-ae1e-4bfc-ba18-211f4e8c9985&client_id=${process.env.REACT_APP_OAUTH_CLIENT_ID}&redirect_uri=${encodeURI(callback)}&scope=openid+profile+email`}
                >
                    Login
                </StyledButton>

            </Body>
        </ThemeProvider>
    );
};

export default LoginPage;
