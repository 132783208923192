import React from 'react';
import Navbar from '../_shared/Navbar';
import {ThemeProvider, createTheme} from '@mui/material';
import serialImage from '../../images/searchICG.png';
import {styled} from '@mui/system';
import {useRef} from 'react';
import {useNavigate} from 'react-router-dom';

const theme = createTheme();

const Body = styled('body')({
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    // minHeight: '100vh',
    margin: 0,
});

const Img = styled('img')({
    textAlign: 'right',
    marginLeft: '10px',
    height: '400px',
});

const ContentWrapper = styled('div')({
    padding: '5%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});

const ImageContainer = styled('div')({
    flex: 1,
    marginRight: '20px',
});

const FormContainer = styled('div')({
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
});

const H3 = styled('h3')({
    color: 'black',
    fontSize: '20px',
    width: '100%',
    marginBottom: '10px',
});

const Form = styled('form')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
});

const Input = styled('input')({
    padding: '10px',
    marginBottom: '10px',
});

const ContinueButton = styled('button')({
    textDecoration: 'none',
    padding: '0.5rem 0.75rem',
    fontSize: '0.75rem',
    fontWeight: 'bold',
    color: 'white',
    border: 1,
    borderColor: 'white',
    backgroundColor: '#129a77',
    boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
    '&:hover': {
        backgroundColor: '#0d7259',
    },
    '&:active': {
        boxShadow: 'none',
        transform: 'scale(0.95)',
    },
});

const SearchICG: React.FC = () => {
    const serialNumber = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();

    function handleClick() {
        const serialInput = (serialNumber.current as HTMLInputElement | null)?.value;
        navigate(`/icg?input=${serialInput}`);
    }

    return (
        <ThemeProvider theme={theme}>

            <Navbar/>
            {/* <AccountMenu /> */}
            <ContentWrapper>
                <ImageContainer>
                    <Img src={serialImage} alt="Logo"/>
                </ImageContainer>
                <FormContainer>
                    <H3>To search the gateway, please enter the ICG Serial below:</H3>
                    <Form>
                        <Input ref={serialNumber} name="serialNumber" id="serialNumber" placeholder="Enter Serial"/>
                        <ContinueButton onClick={handleClick}>Continue</ContinueButton>
                    </Form>
                </FormContainer>
            </ContentWrapper>

        </ThemeProvider>
    );
};

export default SearchICG;