import React from 'react';
import { Props } from './IcgData';
import GatewayConfig from '../../tables/GatewayConfig';
import CommandButtons from '../../tables/CommandButtons';
import GatewayInfo from '../../tables/GatewayInfo';
import GatewayWanStatus from '../../tables/GatewayWanStatus';
import DeviceDataUsage from '../../tables/DeviceDataUsage';
// import RSRP from './tables/RSRP';
import Button from '@mui/material/Button';

const ICG150AND250: React.FC<Props> = ({ icgData }) => {
    if (!icgData) {
        return <div>Loading...</div>
    }

    const { sku } = icgData;

    return (
        <div>
            <div style={{ position: 'relative' }}>
                <h1 style={{ width: '100%', color: 'white', backgroundColor: '#129a77' }}>150/250 Message</h1>
                <span style={{ color: '#129a77' }}> IGUID: {sku?.substring(0, 7)}</span>
            </div>

            <span><CommandButtons icgData={icgData} /></span>

            <span><GatewayInfo icgData={icgData} /></span>

            <span><GatewayConfig icgData={icgData} /></span>
            <div>
                <h1 style={{ color: 'black', paddingRight: '37%', fontSize: 14 }} >Gateway Wan Status: </h1>
                <GatewayWanStatus icgData={icgData} />
            </div>
            {/* TODO: Get data for 150/250 so that this table can be loaded */}
            {/* <RSRP icgData={icgData} /> */}
            <div>
                <h1 style={{ color: 'black', paddingRight: '37%', fontSize: 14 }} >Gateway Data Usage: </h1>
                <DeviceDataUsage icgData={icgData} />
            </div>
            <div style={{ paddingBottom: '5%' }}>
                <Button variant='outlined' style={{ backgroundColor: '#129a77', color: 'white' }}>
                    Search Another Device
                </Button>
            </div>
        </div>
    );
};

export default ICG150AND250;