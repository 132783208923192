import React from 'react';
import { Props } from '../areas/gateways/IcgData';
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';



const Devices: React.FC<Props> = ({ icgData }) => {
    if (!icgData) {
        return <div>Loading...</div>
    }

    const rows: GridRowsProp = icgData.deviceIds.map((deviceId, index) => ({
        id: index + 1,
        DeviceID: deviceId,
        LastContactTime: 'N/A',
        DeviceMac: 'N/A',
        DeviceType: 'N/A',
    }));

    const columns: GridColDef[] = [
        { field: 'DeviceID', headerName: 'Device ID', flex: 1, sortable: false },
        { field: 'LastContactTime', headerName: 'lastContactTime', flex: 1, sortable: false },
        { field: 'DeviceMac', headerName: 'deviceMac', flex: 1, sortable: false },
        { field: 'DeviceType', headerName: 'deviceType', flex: 1, sortable: false },
    ];

    return (
        <div style={{ paddingBottom: '2.5%', width: '700px', backgroundColor: 'white', color: 'white', paddingLeft: '25%' }}>
            <DataGrid
                sx={{
                    borderColor: 'black',
                    "& .MuiDataGrid-cell": {
                        border: 1,
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        border: 1,
                    },
                    "& .MuiDataGrid-columnHeader--withRightBorder": {
                        borderRight: 2,
                    },
                }}
                columnHeaderHeight={25}
                rowHeight={25}
                rows={rows}
                columns={columns}
                hideFooterPagination
                hideFooterSelectedRowCount
                hideFooter
                showColumnVerticalBorder
                disableColumnMenu
                disableColumnFilter
                disableColumnSelector
                showCellVerticalBorder
            />
        </div>
    );
};

export default Devices;