import React from 'react';
import {useSearchParams} from 'react-router-dom';
import GetICG from './GetICG';
import {UserDataStorage} from "../../UserDataStorage";
import Navbar from "../_shared/Navbar";

const Icg: React.FC = () => {
    const [searchParams] = useSearchParams();
    const inputValue = searchParams.get('input');

    const userData = UserDataStorage.getUserData()

    return (
        <>
            <Navbar/>
            <div>
                {inputValue && (
                    // user data is part of a protected route, so it must exist
                    <GetICG accessToken={userData!.AccessToken} serialID={inputValue}/>
                )}
            </div>
        </>
    );
};
export default Icg;