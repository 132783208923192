import React, { useState } from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { createTheme, Menu, MenuItem, IconButton } from '@mui/material';
import intwineLogo from '../../images/intwineconnect_white.png'
import { styled } from '@mui/system';
import {UserDataStorage} from "../../UserDataStorage";
import {useNavigate} from "react-router-dom";

const theme = createTheme();

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    color: 'white',
    '& .MuiSvgIcon-root': {
        fontSize: '2.5rem', // Adjust the font size as needed
    },
    '&:hover': {
        boxShadow: '0 0 0.7px rgba(255, 255, 255, 0.2)',
    },
}));

const StylyedNavbar = styled('div')({
    backgroundColor: '#129a77',
    paddingTop: '0.8em',
    paddingBottom: '0.4em',
    borderTop: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    margin: 0,

});

const LogoImg = styled('img')({
    width: '170px',
    height: '65px',
});

const Navbar = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const handleLogout=()=> {
        UserDataStorage.clearUserData();
        navigate("/login")
    }

    return (
        <StylyedNavbar>
            <LogoImg src={intwineLogo} alt="Logo" />
            <StyledIconButton
                aria-controls="account-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <AccountCircleIcon />
            </StyledIconButton>
            <Menu
                id="account-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleClose}>Add Gateway</MenuItem>
                <MenuItem onClick={handleClose}>Upload Gateway</MenuItem>
                <MenuItem onClick={handleLogout}>Sign Out</MenuItem>
            </Menu>
        </StylyedNavbar >
    );
};
export default Navbar;