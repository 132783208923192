import React from 'react';

const AccessControlCell: React.FC<{ value: string, onChange: (value: string) => void }>
    = ({ value, onChange }) => {
        const REGISTERE_TO_RMP = 1 << 0;
        const ALL_AUTH = 1 << 1;
        const NO_AUTH = 1 << 8;


        const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
            const selectedValue = parseInt(event.target.value);
            let updatedValue = parseInt(value, 10);

            if (event.target.checked) {
                updatedValue |= selectedValue;
            } else {
                updatedValue &= ~selectedValue;
            }

            onChange(updatedValue.toString());
        };

        return (
            <div>
                <div>
                    <label htmlFor="REGISTERE_TO_RMP">REGISTERE_TO_RMP</label>
                    <input
                        type="checkbox"
                        name="REGISTERE_TO_RMP"
                        value={REGISTERE_TO_RMP}
                        checked={(parseInt(value, 10) & REGISTERE_TO_RMP) === REGISTERE_TO_RMP}
                        onChange={handleCheckbox}
                    />
                </div>
                <div>
                    <label htmlFor="ALL_AUTH">ALL_AUTH</label>
                    <input
                        type="checkbox"
                        name="ALL_AUTH"
                        value={ALL_AUTH}
                        checked={(parseInt(value, 10) & ALL_AUTH) === ALL_AUTH}
                        onChange={handleCheckbox}
                    />
                </div>
                <div>
                    <label htmlFor="NO_AUTH">NO_AUTH</label>
                    <input
                        type="checkbox"
                        name="NO_AUTH"
                        value={NO_AUTH}
                        checked={(parseInt(value, 10) & NO_AUTH) === NO_AUTH}
                        onChange={handleCheckbox}
                    />
                </div>
            </div>
        );
    };

export default AccessControlCell;