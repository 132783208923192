import React from 'react';
import {Route, Routes} from 'react-router-dom';
import LoginPage from './areas/auth/LoginPage';
import Callback from './areas/auth/Callback';
import SearchICG from './areas/gateways/SearchICG';
import Icg from './areas/gateways/Icg';
import './styling/App.css';
import AddGateway from './areas/admin/addGateway';
import UploadGateway from './areas/admin/uploadGateway';
import {DefaultLayout} from "./areas/_shared/DefaultLayout";
import {ProtectedLayout} from "./areas/_shared/ProtectedLayout";

function App() {
    return (
        <Routes>
            <Route element={<DefaultLayout/>}>
                <Route path="/login" element={<LoginPage/>}/>
                <Route path="/callback" element={<Callback/>}/>
            </Route>
            <Route element={<ProtectedLayout/>}>
                <Route path="/icg" element={<Icg/>}/>
                <Route path="/" element={<SearchICG/>}/>

                <Route path="/AdminPages/addGateway" element={<AddGateway/>}/>
                <Route path="/AdminPages/uploadGateway" element={<UploadGateway icgData={null}/>}/>
            </Route>
            <Route path="*" element={<p>There's nothing here: 404!</p>}/>
        </Routes>
    );
}

export default App;