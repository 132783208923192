export interface UserData {
    AccessToken: string;
    Email: string;
    Groups: string[]
}

export class UserDataStorage {
    private static instance: UserDataStorage;

    private UserData: UserData|null;
    private Key: string;

    private constructor() {
        this.UserData = null
        this.Key = `${process.env.REACT_APP_OAUTH_CLIENT_ID}-authToken`
    }

    private static getInstance(): UserDataStorage {
        if (!UserDataStorage.instance) {
            UserDataStorage.instance = new UserDataStorage();
        }

        return UserDataStorage.instance;
    }

    public static setUserData(userData: UserData) {
        const instance = UserDataStorage.getInstance();
        instance.UserData = userData;
        localStorage.setItem(instance.Key, JSON.stringify(userData));
    }

    public static clearUserData() {
        const instance = UserDataStorage.getInstance();
        instance.UserData=null;
        localStorage.removeItem(instance.Key)
    }

    public static getUserData(): UserData | null {
        const instance = UserDataStorage.getInstance();
        if (!instance.UserData) {
            const data = localStorage.getItem(instance.Key);
            if (!data) return null;
            try {
                instance.UserData = JSON.parse(data);
            } catch (error) {
                return null;
            }
        }
        return instance.UserData;
    }
}