import React from 'react';
import { Props } from '../areas/gateways/IcgData';
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';


// This component will only ever be two rows
// 1. Name of the values
// 2. The actual values 
const rows: GridRowsProp = [
    { id: 1, blank: '4g', bars: ' ', state: ' ', pri: ' ' },
    { id: 2, blank: 'wifi', bars: ' ', state: ' ', pri: ' ' },
    { id: 3, blank: 'eth', bars: ' ', state: ' ', pri: ' ' },
];

// Defining columns for design purposes
const columns: GridColDef[] = [
    { field: 'blank', headerName: ' ', flex: 1, sortable: false },
    { field: 'bars', headerName: 'bars', flex: 1, sortable: false },
    { field: 'state', headerName: 'state', flex: 1, sortable: false },
    { field: 'pri', headerName: 'pri', flex: 1, sortable: false },
];

const GatewayWanStatus: React.FC<Props> = ({ icgData }) => {
    if (!icgData) {
        return <div>Loading...</div>
    }

    return (
        <div style={{ paddingBottom: '2.5%', width: '30%', backgroundColor: 'white', color: 'white', paddingLeft: '25%' }}>
            <DataGrid
                sx={{
                    borderColor: 'black',
                    "& .MuiDataGrid-cell": {
                        border: 1,
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        border: 1,
                    },
                    "& .MuiDataGrid-columnHeader--withRightBorder": {
                        borderRight: 2,
                    },
                }}
                columnHeaderHeight={25}
                rowHeight={25}
                rows={rows}
                columns={columns}
                hideFooterPagination
                hideFooterSelectedRowCount
                hideFooter
                showColumnVerticalBorder
                disableColumnMenu
                disableColumnFilter
                disableColumnSelector
                showCellVerticalBorder
            />
        </div>
    );
};

export default GatewayWanStatus;