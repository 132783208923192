import React from 'react';
import { Props } from '../areas/gateways/IcgData';
import Button from '@mui/material/Button';
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';

const columns: GridColDef[] = [
    { field: 'name', headerName: 'Name', editable: false, flex: 1, },
    {
        field: 'input',
        headerName: 'Input',
        type: 'String',
        editable: true,
        // 
        flex: 1,
    }
];

const rows: GridRowsProp = [
    { id: 0, name: 'gateway id' },
    { id: 1, name: 'device id/agent id' },
    { id: 2, name: 'transaction type (t)' },
    { id: 3, name: 'attributes (msg). Example: {"some":"attr"}' },
];


const DirectMessage: React.FC<Props> = ({ icgData }) => {
    if (!icgData) {
        return <div>Loading...</div>
    }

    const handleSendMessage = async () => {
        // try {
        //     let accessToken = getToken('access');
        //
        //     const icgdata = await fetch(`${process.env.REACT_APP_API_URL}/gateways/lrwtqhlp4wszdtnz/message`, {
        //         method: 'POST',
        //         headers: {
        //             'Authorization': `Bearer ${accessToken}`,
        //             'Content-Type': 'application/json'
        //         },
        //         body: JSON.stringify({
        //             // name: updatedIcgData.name
        //             // updatedIcgData
        //             // name: "LORAIN_COUNTY_METRO_PARKS_25",
        //             // "billingPlanId": "00002",
        //         }),
        //         // body: JSON.stringify({
        //         //     'name ': 'LORAIN_COUNTY_METRO_PARKS_44'
        //         // }),
        //     });
        //     if (icgdata.ok) {
        //         console.log("Request Accepted and Working");
        //     } else {
        //         console.error('Request failed: ', icgdata.status);
        //     }
        // } catch (error) {
        //     console.error("Error: ", error);
        // }
    };
    // window.location.reload();

    handleSendMessage();
    // sendRequestCompleteRef.current = false;
    // Reload the page to have GET request working

    return (
        <div style={{ paddingBottom: '10%', height: 'flex', width: "50%", backgroundColor: 'white', color: 'white', paddingLeft: '25%' }}>
            <DataGrid
                slots={{
                    columnHeaders: () => null,
                }}
                sx={{
                    borderColor: 'black',
                    "& .MuiDataGrid-cell": {
                        border: 1,
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        border: 1,
                    },
                    "& .MuiDataGrid-columnHeader--withRightBorder": {
                        borderRight: 2,
                    },
                }}
                rowHeight={25}
                rows={rows}
                columns={columns}
                hideFooterPagination
                hideFooterSelectedRowCount
                hideFooter
                showColumnVerticalBorder
                disableColumnMenu
                disableColumnFilter
                disableColumnSelector
                showCellVerticalBorder
            />
            <div style={{ paddingRight: '85%' }}>
                <Button variant='outlined' style={{ backgroundColor: '#129a77', color: 'white' }}
                    onClick={handleSendMessage}>
                    Send
                </Button>
            </div>
        </div>
    );
};

export default DirectMessage;